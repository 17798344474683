import { ActionReducerMap } from '@ngrx/store';
import { sessionAuthData, sessionTokensData, sessionUserData } from '../interfaces/states-data';
import { sessionAuthReducer } from './session-auth/session-auth.reducers';
import { venuesReducer } from './venues/venues.reducers';
import { sessionUserReducer } from './session-user/session-user.reducers';
import { experiencesReducer } from './experiences/experiences.reducers';

export interface AppState {
	sessionAuthState: sessionAuthData;
	sessionUserState: sessionUserData;
	experiencesState: any;
	venuesState: any;
}

export const ROOT_REDUCERS: ActionReducerMap<AppState> = {
	sessionAuthState: sessionAuthReducer,
	sessionUserState: sessionUserReducer,
	experiencesState: experiencesReducer,
	venuesState: venuesReducer
};

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, map, take, takeUntil } from 'rxjs';
import { getSessionAuthData } from '../states/session-auth/session-auth.selectors';
import { sessionAuthData } from '../interfaces/states-data';

@Injectable({
	providedIn: 'root'
})
export class SessionAuthGuard {
	constructor(
		private store: Store<any>,
		private _router: Router
	) {
		console.warn('%c -------> Contructor SessionAuthGuard', 'color: white');
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		console.warn('%c -------> canActivate SessionAuthGuard', 'color: white');

		// La ruta a la cual se navegara si el guard falla
		// se puede establecer desde el route module
		let invalidRoute = route.data['invalidRoute'] ? route.data['invalidRoute'] : 'auth';

		// Se obtiene del reducer el estado de la sesion
		return this.store.pipe(
			select(getSessionAuthData),
			take(1),
			map((data: sessionAuthData) => {
				if (data.loggedIn) {
					return data.loggedIn;
				} else {
					console.warn('%c -------> canActivate SessionAuthGuard -> false', 'color: white');

					// Navegar al login
					this._router.navigate([invalidRoute]);
					return data.loggedIn || false;
				}
			})
		);
	}
}

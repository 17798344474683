import { createReducer, on } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { sessionAuthData } from '../../interfaces/states-data';
import { __clearSessionAuth, __updateSessionAuth } from './session-auth.actions';

const defaultData: sessionAuthData = { loggedIn: false };
const initialState: sessionAuthData = JSON.parse(localStorage.getItem('sessionAuthState') || JSON.stringify(defaultData)) || defaultData;

export const sessionAuthReducer = createReducer(
	initialState,
	immerOn(__updateSessionAuth, (state, newState) => {
		const updatedState = { ...state, ...newState };
		localStorage.setItem('sessionAuthState', JSON.stringify(updatedState));
		return updatedState;
	}),
	immerOn(__clearSessionAuth, (state, newState) => {
		// Borrar estado y tokens del local storage
		localStorage.removeItem('sessionAuthState');
		return { loggedIn: false };
	})
);

import { createReducer, on } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { sessionUserData } from '../../interfaces/states-data';
import { __clearSessionUser, __updateSessionUser } from './session-user.actions';

const defaultData: sessionUserData = {};
const initialState: sessionUserData = JSON.parse(localStorage.getItem('sessionTokensState') || JSON.stringify(defaultData)) || defaultData;

export const sessionUserReducer = createReducer(
	initialState,
	immerOn(__updateSessionUser, (state, newState) => {
		const updatedState = { ...state, ...newState };
		localStorage.setItem('sessionUserData', JSON.stringify(updatedState));
		return updatedState;
	}),
	immerOn(__clearSessionUser, (state, newState) => {
		// Borrar estado del usuario del local storage
		localStorage.removeItem('sessionUserData');
		return { loggedIn: false };
	})
);

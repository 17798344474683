import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app/app.module';

/*platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));*/

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.then(() => {
		if ('serviceWorker' in navigator) {
			//navigator.serviceWorker.register('master-sw.js');
		}
	})
	.catch((err) => console.log(err));

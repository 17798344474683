import { createReducer, on } from '@ngrx/store';
import { immerOn } from 'ngrx-immer/store';
import { __clearVenues, __updateVenues } from './venues.actions';

const initialState: any = null;

export const venuesReducer = createReducer(
	initialState,
	immerOn(__updateVenues, (state, newState) => {
		return newState;
	}),
	immerOn(__clearVenues, (state, newState) => {
		return newState;
	})
);

import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, catchError, filter, switchMap, take, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/features/auth/services/auth.service';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
	private isRefreshing = false;
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(false);

	constructor(private authService: AuthService) {}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		// Si es una solicitud de refrescamiento de token se ignora
		if (request.headers.has('Skip-Refresh-Interceptor')) {
			request = request.clone({
				headers: request.headers.delete('Skip-Refresh-Interceptor')
			});

			return next.handle(request);
		}

		// Si el token es invalido se hace el refresh
		if (!this.authService.validAccessToken) {
			if (!this.authService.validRefreshToken) {
				// Logout
			}

			if (!this.isRefreshing) {
				this.isRefreshing = true;
				this.refreshTokenSubject.next(null);

				return this.authService.refreshToken().pipe(
					switchMap(() => {
						this.isRefreshing = false;
						this.refreshTokenSubject.next(true);
						return next.handle(request.clone({ setHeaders: { 'X-Access-Token': this.authService.getAccessToken } }));
					}),
					catchError((refreshError: any) => {
						this.isRefreshing = false;
						return throwError(() => refreshError);
					})
				);
			} else {
				return this.refreshTokenSubject.pipe(
					filter((token) => token !== null),
					take(1),
					switchMap(() => next.handle(request.clone({ setHeaders: { 'X-Access-Token': this.authService.getAccessToken } })))
				);
			}
		}

		// Se ejecuta el request con normalidad
		return next.handle(request);
	}
}

import { Component, ElementRef, HostListener, afterNextRender } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Store, select } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { fadeInOutYAnimation } from 'src/app/shared/animations/animations';
import { sessionUserData } from 'src/app/shared/interfaces/states-data';
import { __clearSessionAuth } from 'src/app/shared/states/session-auth/session-auth.actions';
import { getSessionUserData } from 'src/app/shared/states/session-user/session-user.selectors';

@Component({
	selector: 'app-app-layout',
	templateUrl: './app-layout.component.html',
	styleUrls: ['./app-layout.component.scss'],
	animations: [
		fadeInOutYAnimation
	]
})
export class AppLayoutComponent {
	protected ngUnsubscribe: Subject<void> = new Subject<void>();

	behaviourStates: any = {
		sidebarOpen: false
	};

	// User States
	sessionUserState: sessionUserData = {};

	userMenuOpen: boolean = false;

	constructor(
		private el: ElementRef,
		private store: Store<any>,
		private jwtHelper: JwtHelperService,
		private _router: Router
	) {
		// Al recibir cambios del reducer - User
		this.store.pipe(select(getSessionUserData), takeUntil(this.ngUnsubscribe)).subscribe((data: sessionUserData) => {
			// Se guarda en el componente local el estado obtenido
			this.sessionUserState = data;
		});

		afterNextRender(() => {
			this.behaviourStates = JSON.parse(localStorage.getItem('states[app-layout]') || JSON.stringify(this.behaviourStates));
		});
	}

	ngOnInit(): void {}

	/* Flipflop sidebar */
	toggleSidebar(close: boolean = false) {
		if (close) {
			this.behaviourStates.sidebarOpen = false;
		} else {
			this.behaviourStates.sidebarOpen = !this.behaviourStates.sidebarOpen;
		}

		localStorage.setItem('states[app-layout]', JSON.stringify(this.behaviourStates));
	}

	/* Flipflop usermenu */
	toggleUserMenu(close: boolean = false) {
		if (close) {
			this.userMenuOpen = false;
		} else {
			this.userMenuOpen = !this.behaviourStates.userMenuOpen;
		}
	}

	/* Al presionar escape cerrar el sidebar */
	@HostListener('document:keydown.escape')
	onKeydownHandler() {
		this.toggleSidebar(true);
		this.toggleUserMenu(true);
	}

	/* Al presionar escape cerrar el sidebar */
	@HostListener('document:click', ['$event'])
	onClickHandler(event: any) {
		// Referencia al boton del menu del usuario
		const userMenuElement = this.el.nativeElement.querySelector('#user-menu');
		const clickedElement = event.target as HTMLElement;

		if (userMenuElement) {
			// Cerrar el menu de usuario si se da click en cualquier otro elemento
			if (clickedElement !== userMenuElement && !userMenuElement.contains(clickedElement)) {
				this.toggleUserMenu(true);
			}
		}
	}

	/* Al entra en md */
	@HostListener('window:resize', ['$event'])
	onresize(event: any) {
		// <<
		if (window.innerWidth < 1024) {
			this.toggleSidebar(true);
		}
	}

	/* Limpiar la sesion de la aplicacion */
	clearSession() {
		// Borrar sesion del reducer
		this.store.dispatch(__clearSessionAuth());
	}
}

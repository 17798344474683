import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { NgModule } from '@angular/core';
import { ApolloClientOptions, ApolloLink, InMemoryCache, RequestHandler } from '@apollo/client/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const uri = `${environment.apiBase}graphql`;
export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
	const authMiddleware = new ApolloLink((operation: any, forward: any) => {
		let token = JSON.parse(localStorage.getItem('sessionAuthState') || '{}').access_token || '';

		operation.setContext({
			headers: new HttpHeaders().set('Authorization', `Bearer ${token}` || '')
		});

		return forward(operation);
	});

	return {
		link: ApolloLink.from([authMiddleware, httpLink.create({ uri })]),
		cache: new InMemoryCache(),
		defaultOptions: {
			query: {
				fetchPolicy: 'network-only'
			}
		}
	};
}

@NgModule({
	exports: [ApolloModule],
	providers: [
		{
			provide: APOLLO_OPTIONS,
			useFactory: createApollo,
			deps: [HttpLink]
		}
	]
})
export class GraphQLModule {}
function setContext(arg0: (operation: any, context: any) => { headers?: undefined } | { headers: { Authorization: string } }) {
	throw new Error('Function not implemented.');
}

import { Component, ViewChild, afterNextRender } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Subject, interval, takeUntil } from 'rxjs';
import { sessionAuthData, sessionTokensData, sessionUserData } from 'src/app/shared/interfaces/states-data';
import { AuthService } from 'src/app/features/auth/services/auth.service';
import { getSessionAuthData } from 'src/app/shared/states/session-auth/session-auth.selectors';
import { DateTime, Duration } from 'luxon';
import { environment } from '../../../../environments/environment';
import { __clearSessionAuth, __updateSessionAuth } from 'src/app/shared/states/session-auth/session-auth.actions';
import { getSessionUserData } from 'src/app/shared/states/session-user/session-user.selectors';
import { __clearSessionUser, __updateSessionUser } from 'src/app/shared/states/session-user/session-user.actions';
import { NgxTippyDefaultProps, NgxTippyService } from 'ngx-tippy-wrapper';
import { FirebaseMessagingService } from 'src/app/shared/services/firebase-messaging/firebase-messaging.service';
import { DeviceUUID } from 'device-uuid';
import { SwUpdate } from '@angular/service-worker';
import { ModalOverlayDirective } from 'src/app/shared/directives/modal-overlay.directive';
import { GlobalBaseComponent } from '../global-base/global-base.component';
import { loadingState } from 'src/app/shared/operators/loading-state.operator';
import { NavigationEnd, Router } from '@angular/router';

declare const clarity: any; // Declarar Clarity globalmente

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent extends GlobalBaseComponent {
	@ViewChild('modalVersionUpdateOverlay') modalVersionUpdateOverlay!: ModalOverlayDirective;

	// Auth States
	sessionTokensState: sessionTokensData = {};
	sessionAuthState: sessionAuthData = { loggedIn: false };
	sessionExist: boolean = false;
	firebaseDeviceToken: string = '';
	deviceID: string = '';

	// User States
	sessionUserState: sessionUserData = {};

	// Json de tokens
	accessTokenData: any = null;
	refreshTokenData: any = null;

	// Intervalos de tiempo
	accessTokenRT: string = '';
	refreshTokenRT: string = '';
	updateSessionRT: string = '';
	accessTokenID: string = ''; // Initial Duration
	refreshTokenID: string = ''; // Initial Duration

	constructor(
		private store: Store<any>,
        private router: Router,
		private authService: AuthService,
		private tippyService: NgxTippyService,
		public worker: SwUpdate
	) {
		super();

		console.warn('%c -------> Contructor App', 'color: white');

		// Definir grupos de estados de carga
		this.loadingStates = {
			authRequest: new BehaviorSubject<boolean>(false)
		};

		afterNextRender(() => {
			console.warn('%c -------> afterNextRender App', 'color: white');

            this.router.events.subscribe(event => {
                if (event instanceof NavigationEnd) {
                    console.log(clarity);
                    // Llama a Clarity en cada cambio de navegación
                    clarity('set', 'pageview');
                }
            });

			// Al recibir cambios del reducer - Auth
			this.store.pipe(select(getSessionAuthData), takeUntil(this.ngUnsubscribe)).subscribe((data: sessionAuthData) => {
				console.warn(`%c -------> loggedIn: ${data.loggedIn}`, 'color: white');

				// Se guarda en el componente local el estado obtenido
				this.sessionAuthState = data;

				// Si los tokens son validos la sesion existe
				if (data.loggedIn && this.authService.validRefreshToken) {
					console.warn('%c -------> Session Exist', 'color: white');
					this.sessionExist = true;

					// Se desencriptan los tokens
					this.refreshTokenData = this.authService.getRefreshTokenData;
					this.accessTokenData = this.authService.getAccessTokenData;
				}

				// Se detecta si otro componente dispara el action de __clearSessionAuth
				// ya que en el componente actual this.sessionExist seguiria manteniendo
				// un valor de sesion por lo que se procede a eliminar la sesion local
				if (!this.sessionAuthState.loggedIn && this.sessionExist) {
					console.warn('%c -------> Logout Condition', 'color: white');
					this.clearSession();
				}
			});

			// Al recibir cambios del reducer - User
			this.store.pipe(select(getSessionUserData), takeUntil(this.ngUnsubscribe)).subscribe((data: sessionUserData) => {
				// Se guarda en el componente local el estado obtenido
				this.sessionUserState = data;
			});

			// Iniciar timer de sesion
			this.sessionUpdate();

			// Iniciar servicio de notificaciones de firebase
			this.firebaseInitialize();

			// Si el worker esta habilitado en la app se inicia la logica de actualizaciones
			if (this.worker.isEnabled) {
				this.serviceWorkerAppUpdater();
			}
		});

		// Modificacion de todos los tippy's de la app
		const tippyProps: NgxTippyDefaultProps = {
			// Previene error en mobile que ocasiona que un boton con
			// tippy realice su funcion pero deje el tippy visible
			touch: 'hold'
		};

		// Aplicar configuracion global de tippy
		this.tippyService.setDefaultProps(tippyProps);
	}

	testService() {
		console.log('Se emitio el servicio');
		this.authService
			.ping()
			.pipe(loadingState(this.loadingStates.authRequest))
			.subscribe((data) => {
				console.log('Respondio el servicio');
			});

		this.authService
			.ping()
			.pipe(loadingState(this.loadingStates.authRequest))
			.subscribe((data) => {
				console.log('Respondio el servicio');
			});

		this.authService
			.ping()
			.pipe(loadingState(this.loadingStates.authRequest))
			.subscribe((data) => {
				console.log('Respondio el servicio');
			});
	}

	/**
	 * Iniciar firebase en app
	 */
	firebaseInitialize() {
		// Inicializar firebase
		FirebaseMessagingService.initialize();

		// Obtener el token de notificaciones de firebase
		FirebaseMessagingService.firebaseDeviceToken$.subscribe((token: string) => {
			this.firebaseDeviceToken = token;
		});
	}

	/**
	 * Comprobar sesion cada 0.5 seg
	 */
	sessionUpdate() {
		interval(1000)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((x) => {
				// Comprobar tokens de sesion
				this.checkSessionLogic();
				this.cdr.detectChanges();
			});
	}

	/**
	 * Comprobar estado de los token de sesion
	 * @returns
	 */
	checkSessionLogic() {
		// Logica (Access & Refresh Token)
		if (this.accessTokenData?.iat && this.refreshTokenData?.iat) {
			// Duracion del token (Refresh)
			let initialRTD = Duration.fromObject(
				DateTime.fromSeconds(this.refreshTokenData.exp, { zone: 'UTC' })
					.diff(DateTime.fromSeconds(this.refreshTokenData.iat, { zone: 'UTC' }))
					.toObject()
			);

			// Duracion de la cuenta regresiva (Refresh)
			let durationRT = Duration.fromObject(DateTime.fromSeconds(this.refreshTokenData.exp, { zone: 'UTC' }).diff(DateTime.now()).toObject());

			// Duracion del token (Access)
			let initialATD = Duration.fromObject(
				DateTime.fromSeconds(this.accessTokenData.exp, { zone: 'UTC' })
					.diff(DateTime.fromSeconds(this.accessTokenData.iat, { zone: 'UTC' }))
					.toObject()
			);

			// Duracion de la cuenta regresiva (Access)
			let durationAT = Duration.fromObject(DateTime.fromSeconds(this.accessTokenData.exp, { zone: 'UTC' }).diff(DateTime.now()).toObject());

			// Duracion de la cuenta regresiva (Para Actualizar Token) (Access)
			let durationUT = Duration.fromObject(DateTime.fromSeconds(this.accessTokenData.exp, { zone: 'UTC' }).minus({ seconds: environment.invalidSessionOffset }).diff(DateTime.now()).toObject());

			// Duracion zero para comparar la condicion
			let zeroDuration = Duration.fromObject({});

			// Impresión Debug
			this.refreshTokenRT = durationRT <= zeroDuration ? '00:00:00' : durationRT.toFormat('hh:mm:ss');
			this.accessTokenRT = durationAT <= zeroDuration ? '00:00:00' : durationAT.toFormat('hh:mm:ss');
			this.updateSessionRT = durationUT <= zeroDuration ? '00:00:00' : durationUT.toFormat('hh:mm:ss');
			this.accessTokenID = initialATD.toFormat('hh:mm:ss');
			this.refreshTokenID = initialRTD.toFormat('hh:mm:ss');

			// Si expira el token de refrescamiento
			if (durationRT <= zeroDuration) {
				console.warn('%c -------> Refresh Token Expired', 'color: white');
				this.clearSession();
				return;
			}

			return;
		}
	}

	/**
	 * Limpiar la sesion de la aplicacion
	 */
	clearSession() {
		console.warn('%c -------> clearSession()', 'color: white');

		// La sesion no existe
		this.sessionExist = false;

		// Borrar sesion del reducer
		this.store.dispatch(__clearSessionAuth());
		this.store.dispatch(__clearSessionUser());

		// Navegar al login
		this._router.navigate(['auth']);
	}

	/**
	 * Iniciar comprobacion de actualizaciones
	 */
	serviceWorkerAppUpdater() {
		const intervalSeconds = 10 * 1000;

		// Comprobar cada intervalSeconds si hay cambios en la app
		interval(intervalSeconds).subscribe(() => {
			this.worker.checkForUpdate();
		});

		// Suscribirse a los cambios detectados por el worker
		this.worker.versionUpdates.subscribe((event) => {
			//NO_NEW_VERSION_DETECTED
			//VERSION_DETECTED
			//VERSION_READY

			if (event.type == 'VERSION_READY') {
				this.modalVersionUpdateOverlay.open();
			}
		});

		/*this.worker.unrecoverable.subscribe((event) => {
      console.log(event);
    });*/
	}

	/**
	 * Actualizar la app
	 */
	applyUpdate() {
		this.worker.activateUpdate().then(() => {
			window.location.reload();
		});
	}
}

import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { getSessionAuthData } from '../../states/session-auth/session-auth.selectors';
import { sessionAuthData } from '../../interfaces/states-data';
import { __updateSessionAuth } from '../../states/session-auth/session-auth.actions';

@Injectable()
export class TokensInterceptor implements HttpInterceptor {
	private sessionAuthData: sessionAuthData = {};

	constructor(private store: Store<any>) {
		// Al recibir cambios del reducer - Tokens
		this.store.pipe(select(getSessionAuthData)).subscribe((data: sessionAuthData) => {
			this.sessionAuthData = data;
		});
	}

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (this.sessionAuthData.accessToken) {
			request = request.clone({
				setHeaders: { 'X-Access-Token': `${this.sessionAuthData.accessToken}` }
			});
		}

		return next.handle(request).pipe(
			tap((event) => {
				if (event instanceof HttpResponse) {
					// State de los tokens
					let authTokens: sessionAuthData = {};

					// Si se recibe algun token es porque la sesion existe
					if (event.headers.has('X-Access-Token')) {
						const at = event.headers.get('X-Access-Token') || '';
						authTokens.accessToken = at;
					}

					// Si se recibe algun token es porque la sesion existe
					if (event.headers.has('X-Refresh-Token')) {
						const rt = event.headers.get('X-Refresh-Token') || '';
						authTokens.refreshToken = rt;
					}

					// Actuallizar el state de los tokens
					if (authTokens.accessToken || authTokens.refreshToken) {
						this.store.dispatch(__updateSessionAuth(authTokens));
					}
				}
			})
		);
	}
}

<router-outlet></router-outlet>

<!-- New Version Detect Modal -->
<ng-template
	appModalOverlay
	#modalVersionUpdateOverlay="appModalOverlay"
	[closeOnEscape]="false"
	[closeBackdropClick]="false"
>
	<div class="bg-white rounded shadow-lg overflow-hidden w-[32rem] max-w-[90vw] max-h-[90dvh] m-auto flex flex-col">
		<div class="px-5 py-3 border-b bg-white border-slate-200 relative top-0">
			<div class="flex justify-between items-center">
				<div class="text-lg font-semibold text-primary-100">Actualización Detectada ⚙️</div>
				<button
					(click)="modalVersionUpdateOverlay.close()"
					class="w-8 h-8 min-w-[2rem] min-h-[2rem] cursor-pointer grid place-content-center p-1 rounded bg-white border border-slate-200 hover:border-slate-300 shadow-sm"
				>
					<i
						class="fa-duotone fa-xmark opacity-75 hover:opacity-100"
						style="font-size: 1rem"
					></i>
				</button>
			</div>
		</div>
		<div class="p-4 relative overflow-y-auto">
			<div class="text-sm">
				<div class="space-y-2">
					<p>Se ha recibido una nueva versión de MiDespacho en tu navegador.</p>
					<div class="font-medium text-slate-800 mb-3">Actualiza para:</div>
					<ul class="space-y-2 mb-5">
						<li class="flex items-center">
							<svg
								class="w-3 h-3 shrink-0 fill-current text-emerald-500 mr-2"
								viewBox="0 0 12 12"
							>
								<path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z"></path>
							</svg>
							<div>Disfrutar las últimas características</div>
						</li>
						<li class="flex items-center">
							<svg
								class="w-3 h-3 shrink-0 fill-current text-emerald-500 mr-2"
								viewBox="0 0 12 12"
							>
								<path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z"></path>
							</svg>
							<div>Mantener tu cuenta segura</div>
						</li>
						<li class="flex items-center">
							<svg
								class="w-3 h-3 shrink-0 fill-current text-emerald-500 mr-2"
								viewBox="0 0 12 12"
							>
								<path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z"></path>
							</svg>
							<div>Prevenir errores</div>
						</li>
					</ul>
					<p class="text-xs text-slate-500">Al presionar el botón "Recargar" aplicaremos los cambios y actualizaremos la ventana actual.</p>
				</div>
			</div>
		</div>
		<div class="py-5 px-4 border-t bg-pearl border-slate-200 sticky bottom-0">
			<div class="flex flex-wrap justify-end space-x-2">
				<button class="btn-sm border-slate-200 hover:border-slate-300 text-slate-600 close-overlay-directive">Cancelar</button>
				<button
					class="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white close-overlay-directive"
					(click)="applyUpdate()"
				>
					Recargar
				</button>
			</div>
		</div>
	</div>
</ng-template>

<!-- Tools de desarrollo -->
<app-dev-tools></app-dev-tools>

<!-- Tool depuracion sesion -->
<ng-template
	appDevTool
	[registerTool]="'Session Auth'"
>
	<div
		class="text-xs"
		*ngIf="!sessionExist"
	>
		<div class="font-medium text-slate-800 mb-2">Autenticación de la Sesión</div>
		<div class="inline-flex flex-col px-4 py-2 rounded-sm text-sm bg-white shadow-sm border border-slate-200 text-slate-600 w-full">
			<div class="flex w-full justify-between items-start">
				<div class="flex">
					<svg
						class="w-4 h-4 shrink-0 fill-current text-amber-500 mt-[3px] mr-3"
						viewBox="0 0 16 16"
					>
						<path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z"></path>
					</svg>
					<div>
						<div class="font-medium text-slate-800 mb-1">Sin Información de Autenticación de la Sesión</div>
						<div>No existe token de acceso en el navegador para depurar la sesión.</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div
		*ngIf="sessionExist"
		class="text-xs"
	>
		<div class="font-medium text-slate-800 mb-2">Autenticación de la Sesión</div>

		<button
			(click)="testService()"
			class="btn bg-indigo-500 hover:bg-indigo-600 text-white"
		>
			Testear Servicio {{ this.loadingStates.authRequest.value }}
		</button>

		<div class="space-y-2">
			<div>
				<label
					class="block text-xs font-medium mb-1"
					for=""
					>Session Update In</label
				>
				<input
					readonly
					class="form-input w-full !px-2 !py-1"
					type="text"
					[(ngModel)]="updateSessionRT"
				/>
			</div>
			<div>
				<label
					class="block text-xs font-medium mb-1"
					for=""
					>Access Token</label
				>
				<input
					readonly
					class="form-input w-full !px-2 !py-1"
					type="text"
					[(ngModel)]="sessionAuthState.accessToken"
				/>
			</div>
			<div>
				<label
					class="block text-xs font-medium mb-1"
					for=""
					>Access Token Duration</label
				>
				<input
					readonly
					class="form-input w-full !px-2 !py-1"
					type="text"
					[(ngModel)]="accessTokenID"
				/>
			</div>
			<div>
				<label
					class="block text-xs font-medium mb-1"
					for=""
					>Access Token Expires In</label
				>
				<input
					readonly
					class="form-input w-full !px-2 !py-1"
					type="text"
					[(ngModel)]="accessTokenRT"
				/>
			</div>
			<div>
				<label
					class="block text-xs font-medium mb-1"
					for=""
					>Refresh Token</label
				>
				<input
					readonly
					class="form-input w-full !px-2 !py-1"
					type="text"
					[(ngModel)]="sessionAuthState.refreshToken"
				/>
			</div>
			<div>
				<label
					class="block text-xs font-medium mb-1"
					for=""
					>Refresh Token Duration</label
				>
				<input
					readonly
					class="form-input w-full !px-2 !py-1"
					type="text"
					[(ngModel)]="refreshTokenID"
				/>
			</div>
			<div>
				<label
					class="block text-xs font-medium mb-1"
					for=""
					>Refresh Token Expires In</label
				>
				<input
					readonly
					class="form-input w-full !px-2 !py-1"
					type="text"
					[(ngModel)]="refreshTokenRT"
				/>
			</div>
			<div>
				<label
					class="block text-xs font-medium mb-1"
					for=""
					>Permissions Hash</label
				>
				<input
					readonly
					class="form-input w-full !px-2 !py-1"
					type="text"
					[(ngModel)]="sessionAuthState.permissions_hash"
				/>
			</div>
			<div>
				<label
					class="block text-xs font-medium mb-1"
					for=""
					>Data User Hash</label
				>
				<input
					readonly
					class="form-input w-full !px-2 !py-1"
					type="text"
					[(ngModel)]="sessionAuthState.data_user_hash"
				/>
			</div>
		</div>
	</div>
</ng-template>

<!-- Tool css breakpoints -->
<ng-template
	appDevTool
	[registerTool]="'CSS Breakpoints'"
>
	<div class="text-xs">
		<div class="font-medium text-slate-800 mb-2">Active Breakpoint</div>

		<div class="inline-flex px-4 py-2 rounded-sm text-sm bg-white shadow-lg border border-slate-200 text-slate-600 xs:bg-indigo-500 xs:text-white">
			<div class="flex w-full justify-between items-start">
				<div class="flex">
					<div>XS.</div>
				</div>
			</div>
		</div>

		<div class="inline-flex px-4 py-2 rounded-sm text-sm bg-white shadow-lg border border-slate-200 text-slate-600 sm:bg-indigo-500 sm:text-white">
			<div class="flex w-full justify-between items-start">
				<div class="flex">
					<div>SM.</div>
				</div>
			</div>
		</div>

		<div class="inline-flex px-4 py-2 rounded-sm text-sm bg-white shadow-lg border border-slate-200 text-slate-600 md:bg-indigo-500 md:text-white">
			<div class="flex w-full justify-between items-start">
				<div class="flex">
					<div>MD.</div>
				</div>
			</div>
		</div>

		<div class="inline-flex px-4 py-2 rounded-sm text-sm bg-white shadow-lg border border-slate-200 text-slate-600 lg:bg-indigo-500 lg:text-white">
			<div class="flex w-full justify-between items-start">
				<div class="flex">
					<div>LG.</div>
				</div>
			</div>
		</div>

		<div class="inline-flex px-4 py-2 rounded-sm text-sm bg-white shadow-lg border border-slate-200 text-slate-600 xl:bg-indigo-500 xl:text-white">
			<div class="flex w-full justify-between items-start">
				<div class="flex">
					<div>XL.</div>
				</div>
			</div>
		</div>

		<div class="inline-flex px-4 py-2 rounded-sm text-sm bg-white shadow-lg border border-slate-200 text-slate-600 2xl:bg-indigo-500 2xl:text-white">
			<div class="flex w-full justify-between items-start">
				<div class="flex">
					<div>2XL.</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<!-- Tool Firebase token -->
<ng-template
	appDevTool
	[registerTool]="'Device'"
>
	<div class="text-xs">
		<div class="font-medium text-slate-800 mb-2">Device ID</div>
		<div class="flex flex-row gap-2">
			<div class="flex-1">
				<app-input-field-maker
					[inputType]="'text'"
					[readOnly]="true"
					[value]="deviceID"
				></app-input-field-maker>
			</div>
			<div>
				<button
					[cdkCopyToClipboard]="firebaseDeviceToken"
					class="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white h-[2.375rem] w-[2.375rem] p-0"
				>
					<div class="w-full h-full grid place-content-center">
						<i
							class="fa-duotone fa-copy"
							style="font-size: 1rem"
						></i>
					</div>
				</button>
			</div>
		</div>
		<div class="font-medium text-slate-800 mb-2">Firebase FCM Token</div>
		<div class="flex flex-row gap-2">
			<div class="flex-1">
				<app-input-field-maker
					[inputType]="'text'"
					[readOnly]="true"
					[value]="firebaseDeviceToken"
				></app-input-field-maker>
			</div>
			<div>
				<button
					[cdkCopyToClipboard]="firebaseDeviceToken"
					class="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white h-[2.375rem] w-[2.375rem] p-0"
				>
					<div class="w-full h-full grid place-content-center">
						<i
							class="fa-duotone fa-copy"
							style="font-size: 1rem"
						></i>
					</div>
				</button>
			</div>
		</div>
	</div>
</ng-template>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './components/app/app.component';
import { AppLayoutComponent } from './components/app-layout/app-layout.component';
import { HomeComponent } from '../features/home/home.component';
import { ExperiencesBillboardComponent } from '../features/home/pages/experiences-billboard/experiences-billboard.component';
import { ExperienceInfoComponent } from '../features/home/pages/experience-info/experience-info.component';
import { SessionAuthGuard } from '../shared/guards/session-auth.guard';
import { NoSessionAuthGuard } from '../shared/guards/no-session-auth.guard';

const routes: Routes = [
	{
		path: 'auth',
		loadChildren: () => import('../features/auth/auth.module').then((m) => m.AuthModule),
		canActivate: [NoSessionAuthGuard]
	},
	{
		path: 'legal',
		component: AppLayoutComponent,
		canActivate: [SessionAuthGuard],
		children: [
			{
				path: 'search',
				loadChildren: () => import('../features/search/search.module').then((m) => m.SearchModule)
			},
			{
				path: 'calendar',
				loadChildren: () => import('../features/calendar/calendar.module').then((m) => m.CalendarMDModule)
			},
			{
				path: '',
				pathMatch: 'full',
				redirectTo: 'search'
			},
			{
				path: '**',
				pathMatch: 'full',
				redirectTo: ''
			}
		]
	},
	{
		path: '**',
		pathMatch: 'full',
		redirectTo: 'auth'
	},

	/* Borrar ↓ */
	{
		path: 'inicio',
		loadChildren: () => import('../features/home/home.module').then((m) => m.HomeModule)
	},
	{
		path: 'home',
		component: AppLayoutComponent,
		canActivate: [SessionAuthGuard],
		children: [
			{
				path: 'dashboard',
				pathMatch: 'full',
				redirectTo: 'experiences'
			},

			{
				path: 'experiences',
				loadChildren: () => import('../features/experiences/experiences.module').then((m) => m.ExperiencesModule)
			},
			{
				path: 'venues',
				loadChildren: () => import('../features/venues/venues.module').then((m) => m.VenuesModule)
			},
			{
				path: 'users',
				loadChildren: () => import('../features/users/users.module').then((m) => m.UsersModule)
			},
			{
				path: 'settings',
				loadChildren: () => import('../features/settings/settings.module').then((m) => m.SettingsModule)
			},
			{
				path: 'admin',
				loadChildren: () => import('../features/admin/admin.module').then((m) => m.AdminModule)
			}
		]
	}
	/* Borrar ↑ */
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {}

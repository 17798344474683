import { ApplicationRef, NgModule, ViewContainerRef, isDevMode } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app/app.component';
import { AppLayoutComponent } from './components/app-layout/app-layout.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { ROOT_REDUCERS } from '../shared/states/app.state';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { JwtModule } from '@auth0/angular-jwt';
import { GraphQLModule } from './graphql.module';
import { TokensInterceptor } from '../shared/interceptors/tokens/tokens.interceptor';
import { CookieService } from 'ngx-cookie-service';
import { GlobalBaseComponent } from './components/global-base/global-base.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { RefreshTokenInterceptor } from '../shared/interceptors/refresh/refresh-token.interceptor';

@NgModule({
	declarations: [
		AppComponent,
		AppLayoutComponent,
		GlobalBaseComponent
	],
	imports: [
		HttpClientModule,
		JwtModule.forRoot({
			config: {
				allowedDomains: ['sarkosgroup.com'],
				tokenGetter: () => JSON.parse(localStorage.getItem('accessToken') || '{}')
			}
		}),
		SharedModule,
		FormsModule,
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		StoreModule.forRoot(ROOT_REDUCERS),
		StoreDevtoolsModule.instrument({ connectInZone: true }),
		StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode(), connectInZone: true }),
		GraphQLModule,
		ServiceWorkerModule.register('master-sw.js', {
			enabled: false,
			registrationStrategy: 'registerImmediately'
		})

	],
	providers: [
		CookieService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: RefreshTokenInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokensInterceptor,
			multi: true
		},
		{
			// Se utiliza para permitir la generacion de modales por el servicio -> ModalActionService
			provide: 'APP_VIEW_CONTAINER_REF',
			useFactory: (appRef: ApplicationRef) => {
				return appRef.components[0].injector.get(ViewContainerRef);
			},
			deps: [ApplicationRef]
		},
		provideClientHydration()

	],
	bootstrap: [AppComponent]
})
export class AppModule {}
